import React from 'react';
import { StrictMode } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { createRoot } from 'react-dom/client';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from 'src/App';
import 'src/index.css';
import 'react-toastify/dist/ReactToastify.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
