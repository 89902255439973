import axios, { AxiosError, AxiosInstance } from 'axios';

export const axiosInstance: AxiosInstance = axios.create({
  // https://devapiplatform.dispatch42.school
  //dev - http://192.168.1.189:3000
  //test  'https://devapiplatform.dispatch42.school'
  baseURL: 'https://apiedu.trucking42.school' || '',
  // baseURL: 'http://192.168.1.53:3000',
  timeout: 15000,
  timeoutErrorMessage: "Server not responding. Please try again later",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      console.error('Code 401 (Unauthorized)');
      error.response.data = 'Unauthorized error';
    }

    if (error.response.status >= 500 && error.response.status < 600) {
      error.response.data = 'Server error';
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
