import React from "react";
import { Helmet } from "react-helmet";
import isEmpty from "lodash/isEmpty";
import { Navigate, useLocation } from "react-router-dom";
import { localStorage } from "src/ulils/localstorage";
import { getParsetQuery } from "../ulils/parseQuery";
import { useGetUserInfoQuery } from "src/redux/API/User/Auth";

export const Autorized = ({ children }: { children: JSX.Element }) => {
  const { search, pathname } = useLocation();
  const token = localStorage({ key: "token" }).getItem();
  const { pid } = getParsetQuery(search) ?? {};
  const user = useGetUserInfoQuery({});

  if (isEmpty(token)) {
    return <Navigate to="/auth" state={{ pid, pathname }} />;
  }

  if (!user.data) return null;

  return (
    <>
      <Helmet>
        <script src="//code.jivo.ru/widget/fzrH0bztmI" async></script>
      </Helmet>
      {children}
    </>
  );
};
